@media #{$sm-layout} {
    .mymodal {
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        width: 100%;
    }
    .ebook-cover-101 {
        width: 140px;
    }
    .ebook-copy {
        display: none;
    }
}


.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #999;
    background: rgba(245, 245, 245, 1);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px;
  }

  .mymodal > .modal-close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #5e5e5e;
    cursor: pointer;
    font-size: 1.25em;
    padding: 7px;
    background: rgba(255, 255, 255, 0.749);
    border: 1px solid #c3c0c0;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
  }
  
  .mymodal > .modal-close:hover {
    background: rgba(255, 255, 255, 0.989);
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }